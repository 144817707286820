<script setup>
const localePath = useLocalePath()

const links = [
  {
    label: 'Principale',
    children: [
      {
        label: 'Acasă',
        to: localePath('index'),
        icon: 'i-heroicons-home',
      },
      {
        label: 'Piese auto',
        to: localePath('parts'),
        icon: 'i-heroicons-wrench',
        children: [
          {
            label: 'Caut o piesa',
            to: localePath('parts'),
            icon: 'i-heroicons-clipboard',
          },
          {
            label: 'Vand o piesa',
            to: localePath('parts-search-requests'),
            icon: 'i-heroicons-magnifying-glass',
          },
          {
            disabled: true,
            label: 'Dezmembrare',
            to: localePath('parts-dismantling'),
            icon: 'i-material-symbols-recycling-rounded',
          },
        ]
      },
      // {
      //   label: 'Autoturisme',
      //   to: localePath('vehicles'),
      //   icon: 'i-material-symbols-directions-car-outline-rounded',
      //   children: [
      //     {
      //       label: 'Arhiva import',
      //       to: localePath('vehicles-archive'),
      //       icon: 'i-heroicons-archive-box',
      //     },
      //   ],
      // },
      {
        label: 'Catalog',
        footer: true,
        to: localePath('/catalog'),
        icon: 'i-heroicons-book-open',
      },
    ]
  },
  {
    label: 'Instrumente',
    to: localePath('tools'),
    icon: 'i-material-symbols-service-toolbox-outline-rounded',
    children: [
      {
        label: 'Toate',
        to: localePath('tools'),
        icon: 'i-material-symbols-background-grid-small'
      },
      {
        label: 'Devamarea din 2026',
        to: localePath('tools-import-future'),
        icon: 'i-fluent-emoji-high-contrast-customs',
      },
      {
        label: 'Devamare / Import 2024',
        to: localePath('tools-import'),
        icon: 'i-fluent-emoji-high-contrast-customs',
      },
      {
        label: 'Lost & Found',
        to: localePath('tools-lost-found'),
        icon: 'i-material-symbols-filter-center-focus-outline-rounded',
      },
      {
        label: 'Calculator RCA',
        to: localePath('tools-insurance-calculator'),
        icon: 'i-material-symbols-android-calculator',
      },
      {
        label: 'Calculator Carte Verde',
        to: localePath('tools-carte-verde'),
        icon: 'i-material-symbols-trip-outline',
      },
      {
        label: 'Istoricul daunelor',
        to: localePath('tools-insurance-claims-history'),
        icon: 'i-material-symbols-car-crash-outline-rounded',
      },
      {
        label: 'Verifică Polița RCA',
        to: localePath('tools-insurance-check'),
        icon: 'i-material-symbols-bookmark-check-outline-rounded',
      },
      {
        label: 'Bonus Malus',
        to: localePath('tools-bonus-malus'),
        icon: 'i-material-symbols-assignment-ind',
      },
      {
        label: 'Combustibil',
        to: localePath('tools-fuel'),
        icon: 'i-material-symbols-local-gas-station-outline-rounded',
      },
      {
        label: 'Emisii',
        to: localePath('tools-emissions'),
        icon: 'i-material-symbols-co2-rounded',
      },
    ],
  },
  {
    label: 'Legal',
    footer: true,
    children: [
      {
        label: 'Toate',
        to: localePath('legal'),
      },
      {
        label: 'Termeni',
        to: localePath('legal-terms'),
        icon: 'i-material-symbols-other-admission-outline-rounded',
      },
      {
        label: 'Confidențialitate',
        to: localePath('legal-privacy'),
        icon: 'i-material-symbols-private-connectivity-outline-rounded',
      },
      {
        label: 'Cookie-uri',
        to: localePath('legal-cookies'),
        icon: 'i-material-symbols-cookie',
      },
    ]
  },
]
</script>

<template>
  <div>
    <Header :links="links" />

    <UMain>
      <slot />
    </UMain>

    <Footer :links="links" />
  </div>
</template>
