<script setup>
const props = defineProps({
  links: {
    type: Array,
    required: true,
  },
})

const route = useRoute()
const localePath = useLocalePath()

const headerLinks = ref(
  structuredClone(props.links).map((group) => {
    let children = group.children.map((child) => {
      if (!child?.footer) {
        return child
      }
    }).flat().filter(Boolean)

    group.children = children

    if (!group?.footer) {
      if (group?.to) {
        return group
      }

      return group?.children
    }
  }).flat().filter(Boolean)
)
</script>

<template>
  <UHeader :links="headerLinks" :to="localePath('index')">
    <template #logo>
      <NuxtImg src="/logo.svg" alt="Logo" width="128" height="64" />
      <span class="text-sm text-gray-400 dark:text-gray-600">
        by smotra.md
      </span>

      <!-- <NuxtImg class="dark:hidden block" src="/logo-display.png" alt="Logo" width="40" height="40" />
      <NuxtImg class="hidden dark:block" src="/logo-display-dark.png" alt="Logo" width="40" height="40" /> -->
    </template>

    <!-- <template #right>
      <ClientOnly>
        <template v-if="!store.token">
          <UButton label="Login" to="/auth/login" color="black" />
          <UButton label="Register" to="/auth/signup" color="black" variant="ghost" class="hidden md:flex"
            icon="i-heroicons-arrow-right-20-solid" trailing />
        </template>
        <template v-else>
          <UButton label="Dashboard" color="black" to="/dashboard" />
          <UButton icon="i-heroicons-bolt-slash" color="red" variant="ghost" @click="store.logout" />
        </template>
      </ClientOnly>
    </template> -->

    <template #panel>
      <UNavigationTree :links="headerLinks" default-open />
    </template>

    <template #bottom>
      <UButton v-if="route.path !== localePath('tools-import-future')" color="primary" variant="soft"
        class="w-full rounded-none" :to="localePath('tools-import-future')">
        <span class="w-full text-center">
          Devamarea se modifica de la 1 ianuarie 2026!
        </span>
      </UButton>
    </template>
  </UHeader>
</template>
