<script setup>
const props = defineProps({
  links: {
    type: Array,
    required: true,
  },
})

const toast = useToast()

const email = ref('')
const loading = ref(false)

function onSubmit() {
  loading.value = true

  setTimeout(() => {
    toast.add({
      title: 'Te-ai abonat!',
      description: 'Vei primi emailuri cu noutăți și oferte speciale.',
    })

    loading.value = false
  }, 1000)
}
</script>

<template>
  <UFooter>
    <template #top>
      <UFooterColumns :links="links">
        <!-- <template #right>
          <form @submit.prevent="onSubmit">
            <UFormGroup label="Abonațivă la newsletter" :ui="{ container: 'mt-3' }">
              <UInput v-model="email" type="email" placeholder="Introduceți adresa de email"
                :ui="{ icon: { trailing: { pointer: '' } } }" required size="xl" autocomplete="off" class="max-w-sm"
                input-class="rounded-full">
                <template #trailing>
                  <UButton type="submit" size="xs" color="primary" :label="loading ? 'Abonare' : 'Abonează-te'"
                    :loading="loading" />
                </template>
</UInput>
</UFormGroup>
</form>
</template>
</UFooterColumns> -->
        <!-- <template #right>
          For inquiries, suggestions, feature requests or just to say hi, drop me an email at
          <UButton to="mailto:me@markmelnic.com" variant="link">
            me@markmelnic.com
            <Icon name="i-heroicons-arrow-up-right-20-solid" />
          </UButton>
        </template> -->
      </UFooterColumns>
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        mobile.md © {{ new Date().getFullYear() }}. Toate drepturile rezervate.
      </p>
    </template>

    <template #right>
      <UColorModeButton size="sm" />

      <!-- <UButton to="https://github.com/nuxt-ui-pro/saas" target="_blank" icon="i-simple-icons-github" aria-label="GitHub"
        color="gray" variant="ghost" /> -->
    </template>
  </UFooter>
</template>
